
import { View } from 'client-website-ts-library/plugins';
import { Component, Mixins } from 'vue-property-decorator';

interface SitemapRoute {
  title: string;
  path: string;
}

@Component
export default class Sitemap extends Mixins(View) {
  private routes: SitemapRoute[] = [];

  mounted() {
    this.routes = this.$router.options!.routes!
      .filter((route) => route.meta?.hide !== true)
      .map((route) => ({
        title: route.name!,
        path: route.path!,
      }));
  }
}
